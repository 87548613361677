import React from "react";
// import ReactDOM from "react-dom"; //, { hydrate, render } //scripts: "postbuild": "react-snap"
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";

// ReactDOM.render(<App />, document.querySelector("#root"));

//UPDATED React Ver18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //   <React.StrictMode>
  <App />
  //   </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
