import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Share from "./component/Share";
import Axios from "axios";
// import HelmetMetaData from "./component/HelmetMetaData";

//Declaration
const url_string = window.location.href; //absolute URL
const path_name = window.location.pathname; //current url MINUS Domain name

const urlStr = new URL(url_string);
//Get Params
const id = urlStr.searchParams.get("id") ? urlStr.searchParams.get("id") : "";
//Get Segment
// const segment = path_name.split("/");
// const segmen1 = segment[1];
// console.log(urlStr);
// console.log(`id: ${id}`);
// console.log(`pname: ${path_name}`);
// console.log(`segment1: ${segmen1}`);

const title = "The Title";
const description = "The Description";
const image = "https://demobot.inq.news/assets/HOTD_Viserys_Card.png";
const url = `https://demobot.inq.news/hotd/${id}`; //"https://demobot.inq.news/hotd/3"; // + id; // + id; //https://demobot.inq.news/hotd/7
const hashtag = "#HOTD#HouseOfTheDragon#HBOoriginalseries#Cignal"; // "#HOTD#HouseOfTheDragon#HBOoriginalseries#Cignal";
const quote = "This should the quote description";
const urlServer = "";
// console.log("LOGI");
const App = () => {
  const [valid, setValid] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [hashtag, setHashtag] = useState("");
  const [quote, setQuote] = useState("");

  useEffect(() => {
    getHOTDCharacter(id);
  }, [image]);

  function getHOTDCharacter(id) {
    //EMPTY
    if (id.trim().length !== 0) {
      return Axios.get(`https://demobot.inq.news/hotdDetails/${id}`, {})
        .then((res) => {
          if (res.data) {
            // console.log(res.data);
            setValid(true);
            setTitle(res.data["title"]);
            setImage(res.data["image"]);
            setDescription(res.data["description"]);
            setHashtag(res.data["hashtag"]);
            setQuote(res.data["quote"]);
          } else {
            setValid(false);
            // console.log("No Data!");
          }
        })
        .catch((err) => console.log("Err: " + err));
    }
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Share
              valid={valid}
              title={title}
              description={description}
              picture={image}
              hashtag={hashtag}
              quote={quote}
              url={url}
            />
          }
        />
      </Routes>
      {/* <Share></Share> */}
    </Router>
  );
};

export default App;
