import React, { useState, useEffect } from "react";
import "./share.css";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { FaFacebookF } from "react-icons/fa";
import HelmetMetaData from "./HelmetMetaData";

const Share = (props) => {
  // const [valid, setValid] = useState(false);

  // const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  // const [picture, setPicture] = useState("");
  // const [url, setUrl] = useState("");
  // const [hashtag, setHashtag] = useState("");
  // const [quote, setQuote] = useState("");

  // useEffect(() => {
  //   // document.title = "MY NEW TITLE";
  //   getData();
  // }, [title, picture, description, url, hashtag, quote]);

  // function getData() {
  // setTitle(props.title);
  // setDescription(props.description);
  // setPicture(props.picture);
  // setUrl(props.url);
  // setHashtag(props.hashtag);
  // setQuote(props.quote);
  // }
  // setValid(props.valid);
  const valid = props.valid;
  const title = props.title;
  const description = props.description;
  const picture = props.picture;
  const hashtag = props.hashtag;
  const quote = props.quote;
  const url = props.url;

  console.log(picture);
  console.log(description);
  return (
    <div className="container share__container">
      <HelmetMetaData
        title={title}
        description={description}
        image={picture}
        hashtag={hashtag}
        currentUrl={url}
      ></HelmetMetaData>
      {valid ? (
        <div>
          <div className="share-img">
            <img src={picture} alt="hotd image" />
          </div>
          <div className="share-btn">
            <FacebookShareButton
              className="fb-share"
              url={url}
              quote={quote}
              hashtag={hashtag}
            >
              <FaFacebookF className="fb-icon" size={20} />
              <button className="btn btn-primary">Share</button>
            </FacebookShareButton>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Share;

{
  /* <div className="fb-icon"> */
}
{
  /* <FaFacebookF size={23} /> */
}
{
  /* <FacebookIcon className="fb-icon" size={36} /> */
}

{
  /* <FacebookShareCount url={shareUrl}>
          {(shareCount) => (
            <span className="myShareCountWrapper">{shareUrl}</span>
          )}
        </FacebookShareCount> */
}

{
  /* <TwitterShareButton
className="fb-share"
url={shareUrl}
title={quote}
// via={"String"}
hashtags={hashtagTwitter}
// url={picture}
>
<TwitterIcon className="fb-icon" size={32} round={true} />
<button className="btn btn-primary">Twitter</button>
</TwitterShareButton> */
}

{
  /* <ShareToFB /> */
}

// const title = "Share Title";
// const description = "This is the description of this image";
// const quote = "Coming Soon... ";
// const picture = "https://demobot.inq.news/assets/HOTD_Alicent_Card.png";
// // const picture =
// //   "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/783px-Test-Logo.svg.png";
// const shareUrl = "https://testdev.leszagon.com";
// const url = "https://testdev.leszagon.com";
// const hashtag = "#HOTD#HouseOfTheDragon#HBOoriginalseries#Cignal";
// const pageDescription = "This is a Page Descriptipn testing ";
// const hashtagTwitter = [
//   "HOTD",
//   "HouseOfTheDragon",
//   "HBOoriginalseries",
//   "Cignal",
// ];
