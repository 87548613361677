import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const HelmetMetaData = (props) => {
  const title = props.title;
  const image = props.image;
  const description = props.description;
  const url = props.currentUrl;
  const hashtag = props.hashtag;

  return (
    <Helmet>
      {/* <title>{title}</title> */}
      <title>Cignal</title>
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="966242223397117" />
      <meta name="description" content={description} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure" content={image} />
      <meta property="og:image:alt" content="HOTD Image" />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:hashtag" content={hashtag} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default HelmetMetaData;

// const hashtag = props.hashtag;
// const quote = props.quote;
// let location = useLocation();
// let currentUrl = "https://demobot.inq.news" + location.pathname;
// let currentUrl = "https://testdev.leszagon.com/" + location.pathname;
// let currentUrl = props.currentUrl !== undefined ? props.currentUrl : "";
// let quote = props.quote !== undefined ? props.quote : "";
// let title = props.title !== undefined ? props.title : "Cignal Meta";
// let image =
//   props.image !== undefined
//     ? props.image
//     : "https://demobot.inq.news/assets/HOTD_Alicent_Card.png";
// let description =
//   props.description !== undefined
//     ? props.description
//     : "CampersTribe lets you experience the camping culture. We discover the hidden gems in the nearby to help you connect with nature & yourself by learning in the woods, on the riverbank under the open sky." +
//       "Trust us!";
// let hashtag = props.hashtag !== undefined ? props.hashtag : "#camperstribe";

{
  /* <meta property="og:site_name" content="CampersTribe" /> */
}

{
  /* <meta
        property="og:image:secure_url"
        content="https://demobot.inq.news/assets/HOTD_Alicent_Card.png"
      /> */
}
{
  /* <meta content="image/*" property="og:image:type" /> */
}

{
  /* <meta property="og:quote" content={quote} /> */
}
{
  /* <meta property="og:hashtag" content={hashtag} /> */
}

{
  /* <meta charset="utf-8" /> */
}
{
  /* <meta http-equiv="X-UA-Compatible" content="IE=edge" /> */
}
{
  /* <meta name="csrf_token" content="" /> */
}
{
  /* <meta property="type" content="website" /> */
}
{
  /* <meta property="url" content={url} /> */
}
{
  /* <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> */
}
{
  /* <meta name="msapplication-TileColor" content="#ffffff" /> */
}
{
  /* <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" /> */
}
{
  /* <meta name="theme-color" content="#ffffff" /> */
}
{
  /* <meta name="_token" content="" /> */
}
{
  /* <meta name="robots" content="noodp" /> */
}
{
  /* <meta property="title" content={title} /> */
}
{
  /* <meta property="quote" content={quote} /> */
}
{
  /* <meta name="description" content={description} /> */
}
{
  /* <meta property="image" content={image} /> */
}
{
  /* <meta property="og:locale" content="en_US" /> */
}
{
  /* <meta property="og:type" content="website" /> */
}

{
  /* <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={currentUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} /> */
}
